.logoContainer {
    display: flex;
    width: 200px;
    height: 100px;
    cursor: pointer;
  
    img {
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }
  