.home-banner-container {
  position: relative;
  height: 400px; // Default height for mobile screens
  background-image: url("../../../assets/images/home/Main-banner-pc.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(to top right, rgba(0, 0, 0, 0.5), rgba(6, 82, 233, 0.493));
    z-index: 1;
  }

  // Default styles for mobile
  .banner-content-container {
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px; // Suitable gap for mobile
    color: $white-color;
    width: 90%; // Mobile width
    text-align: center;
    padding: 10px; // Mobile padding

    .main-content {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      h1 {
        font-size: $title-font-size-sm; // Mobile font size
      }
      .logo-container {
        // width: 150px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    h2 {
      font-size: $title-font-size-xxs; // Mobile font size
    }
  }

  // Adjustments for larger screens
  @include breakpoint-sm {
    height: 500px;
    .banner-content-container {
      width: 65%; // Adjust width for small devices
      gap: 15px;
      padding: 15px;

      h1 {
        font-size: $title-font-size-md; // Adjust for sm screens
      }
    }
  }

  @include breakpoint-md {
    height: 600px;
    .banner-content-container {
      width: 60%; // Adjust width for medium devices
      // Keep adjusting font sizes or other properties as needed
      h1 {
        font-size: $title-font-size-lg; // Adjust for md screens
      }

      h2 {
        font-size: $title-font-size-xs; // Mobile font size
      }
    }
  }

  @include breakpoint-lg {
    height: 732px; // Height for large screens
    .banner-content-container {
      width: 720px; // Fixed width or percentage based on preference for larger screens
      // Further adjustments for larger screens

      .main-content {
        .logo-container {
          width: 250px;
        }
      }
    }
  }
}
