@import 'variables';

@mixin breakpoint-xs { @media (min-width: $breakpoint-xs) { @content; } }
@mixin breakpoint-sm { @media (min-width: $breakpoint-sm) { @content; } }
@mixin breakpoint-md { @media (min-width: $breakpoint-md) { @content; } }
@mixin breakpoint-lg { @media (min-width: $breakpoint-lg) { @content; } }
@mixin breakpoint-xl { @media (min-width: $breakpoint-xl) { @content; } }


@mixin respond-to($breakpoint) {
    @if $breakpoint == small {
        @media (max-width: $breakpoint-sm) { @content; }
    } @else if $breakpoint == medium {
        @media (max-width: $breakpoint-md) { @content; }
    } @else if $breakpoint == large {
        @media (max-width: $breakpoint-lg) { @content; }
    } @else if $breakpoint == xlarge {
        @media (max-width: $breakpoint-xl) { @content; }
    }
}