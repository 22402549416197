.home-about-container {
  display: flex;
  flex-direction: column-reverse;
  padding: 50px; // Adjusted for mobile
  gap: 30px; // Suitable gap for mobile
  align-items: center; // Center align items for mobile

  @include breakpoint-md {
    flex-direction: row; // Side by side for larger screens
    padding: 100px 90px; // Adjusted for larger screens
    gap: 100px;
    align-items: flex-start; // Align items to start for larger screens
  }

  .about-image-container {
    width: 100%; // Full width for mobile
    display: flex;
    justify-content: center;

    img {
      width: 100%; // Make image responsive
      max-width: 600px; // Limit image width
      height: auto; // Maintain aspect ratio
    }

    @include breakpoint-md {
      width: 50%; // Adjust width for larger screens
    }
  }

  .about-content-container {
    width: 100%; // Full width for mobile
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include breakpoint-sm {
      width: 50%; // Adjust width for larger screens
    }

    // h2,
    // pre,
    // .specialty-list-container {
    //   text-align: left;
    // }

    p {
      letter-spacing: 0.52px;
      white-space: pre-wrap;
    }

    .specialty-list-container {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .specialty-container {
        display: flex;
        gap: 15px;
        align-items: center;
        background: #ffffff69;
        border-radius: 14px;
        box-shadow: 0px -2px 127px 0px rgba(5, 19, 142, 0.08);
        padding: 10px 25px; // Adjust padding
        width: 100%; // Full width to adapt to parent

        .checked-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 25px;
          height: 25px;
          border: 3px solid #ff767a;
          border-radius: 50%;
          color: #ff767a;
        }
      }
    }
  }
}
