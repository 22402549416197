@import "../../base/variables";

.homeClientsContainer {
  text-align: center;

  h2 {
    font-size: $title-font-size-md;

    @media (max-width: 480px) {
      font-size: $title-font-size-sm;
    }
  }
}

.logo-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Increased height for better visibility */

  @media (max-width: 768px) {
    height: 160px; /* Adjust for tablet screens */
  }

  @media (max-width: 480px) {
    height: 140px; /* Adjust for mobile devices */
  }
}

.logo-wrapper {
  width: 180px; /* Increased size for desktop view */
  height: 180px; /* Keep it proportional */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #fff; /* Optional: Background color for logos */
  border-radius: 15px; /* Optional: Rounded corners for better aesthetics */
  //   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for a polished look */

  @media (max-width: 768px) {
    width: 150px; /* Adjust size for tablet screens */
    height: 150px;
  }

  @media (max-width: 480px) {
    width: 120px; /* Adjust size for mobile screens */
    height: 120px;
  }
}

.logo-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;

  @media (max-width: 768px) {
    max-width: 90%; /* Slight reduction for tablet screens */
    max-height: 90%;
  }

  @media (max-width: 480px) {
    max-width: 80%; /* Further reduction for mobile devices */
    max-height: 80%;
  }
}
