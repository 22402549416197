.blog-article-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    text-align: right;
    direction: rtl;
  
    h1 {
      font-size: $title-font-size-lg;
      color: $primary-color;
      margin-bottom: 20px;
    }
  
    p {
      font-size: $font-size-base;
      line-height: 1.8;
      color: $secondary-color;
      margin-bottom: 20px;
    }
  }
  