.service-page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  direction: rtl;
  text-align: right;

  h1 {
    color: $primary-color;
  }

  p {
    color: $secondary-color;
    font-size: $font-size-base;
    line-height: 1.8;
    color: $secondary-color;
    margin-bottom: 20px;
  }

  ul {
    list-style: disc inside;
    li {
      color: $secondary-color;
      font-size: $font-size-base;
      line-height: 1.8;
      margin-bottom: 10px;
    }
  }
}
