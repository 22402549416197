.headerContentContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
  padding: 10px;
  z-index: 1000;

  @include breakpoint-md {
    position: static;
    flex-direction: row;
    align-items: center;
    gap: 20px;

    top: auto;
    left: auto;
    width: auto;
    border-top: none;
  }

  .routeContainer {
    position: relative;
    padding: 5px 10px;

    a {
      text-decoration: none;
      color: #333;
      font-weight: 500;

      display: flex;
      gap: 10px;

      &:hover {
        color: #007bff;
      }
    }

    .subRoutesContainer {
      //   display: none;
      top: 100%;
      left: 0;
      background-color: #fff;
      border-radius: 4px;
      z-index: 100;

      @include breakpoint-md {
        display: none; // Ensure it starts hidden for desktop by default.
        position: absolute;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        border: 1px solid #eaeaea;
      }

      .subRouteContainer {
        display: block;
        padding: 5px 30px;
        color: #333;
        text-decoration: none;
        white-space: nowrap;
        border-bottom: 1px solid #eaeaea;

        &:hover {
          background-color: #f0f0f0;
          color: #007bff;
        }
      }
    }

    // Show submenu on hover for desktop
    @include breakpoint-md {
      &:hover .subRoutesContainer {
        display: block;
      }
    }
  }
}
