.home-contact-us-container {
  flex-shrink: 0;
  padding: 50px 30px;

  @include breakpoint-md {
    padding: 50px 90px;
  }

  .contact-us-container {
    position: relative;
    border-radius: 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    gap: 30px;
    padding: 25px;

    background-image: url("../../../assets/images/home/contact1.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint-md {
      background: #f4f9ff;
      flex-direction: row;
      justify-content: space-around;
      height: 348px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top right, rgba(0, 0, 0, 0.5), rgba(6, 82, 233, 0.5));
      z-index: 1;
      border-radius: 50px;
      @include breakpoint-md {
        border-top-right-radius: 80px;
        border-bottom-right-radius: 80px;
      }
    }

    .content-container {
      // width: 35%;
      z-index: 2;
      color: $white-color;
      text-align: center;
      @include breakpoint-md {
        width: 45%;
      }
    }

    .decor-element-background {
      position: absolute;
      right: 0;
      height: 100%;
      display: none;

      @include breakpoint-md {
        display: block;
      }
    }

    .form-container {
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      gap: 10px;

      .input-container {
        height: 50px;
        flex-shrink: 0;
        border-radius: 60px;
        border: 1px solid #f1f1f1;
        background: #fff;
        box-shadow: 10px 20px 50px 0px rgba(0, 0, 0, 0.15);
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @include breakpoint-lg {
          width: 479px;
        }
        @include breakpoint-xl {
          width: 479px;
        }
        @include breakpoint-md {
          width: 379px;
          height: 80px;
        }

        input {
          border: none;
          width: 100%;
          padding: 10px 0;

          &:focus-visible {
            border: none;
          }

          &:focus {
            outline: none;
          }
        }

        .error {
          font-size: 8px;
          color: red;

          @include breakpoint-md {
            font-size: 12px;
          }
        }
      }

      button {
        @include breakpoint-md {
          position: absolute;
          left: -50px;
          top: 30%;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
