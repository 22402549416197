.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh; // Full viewport height
}

.content-wrapper {
  flex: 1; // Push the footer to the bottom
  padding: 20px; // Optional padding for the main content
}



footer {
  padding: 30px 20px 10px;
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;

  .detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .contactDetails {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
    }
  }

  .termsContainer {
    display: flex;
    justify-content: center;
    font-size: $font-size-sm;
  }
}