.about-us-container {
  max-width: $breakpoint-xl; // Matches the 1200px breakpoint
  margin: 0 auto;
  padding: 20px;
  direction: rtl; // For right-to-left content alignment
  text-align: right;

  h1 {
    // font-size: $title-font-size-lg;
    color: $primary-color;
    margin-bottom: 20px;

    // @include breakpoint-md {
    //   font-size: $title-font-size-md;
    // }

    // @include breakpoint-sm {
    //   font-size: $title-font-size-sm;
    // }
  }

  p {
    font-size: $font-size-base;
    line-height: 1.8;
    color: $secondary-color;
    margin-bottom: 20px;

    // @include breakpoint-md {
    //   font-size: $font-size-sm;
    // }

    // @include breakpoint-sm {
    //   font-size: $font-size-xs;
    // }
  }

  h2 {
    // font-size: $subtitle-font-size-md;
    // color: $primary-color;
    // margin-bottom: 15px;

    // @include breakpoint-md {
    //   font-size: $subtitle-font-size-sm;
    // }

    // @include breakpoint-sm {
    //   font-size: $font-size-base;
    // }
  }

  ul {
    list-style: disc inside;
    li {
      color: $secondary-color;
      font-size: $font-size-base;
      line-height: 1.8;
      margin-bottom: 10px;
    }
  }

  // ul {
  //   list-style: disc inside;
  //   margin-bottom: 20px;
  //   padding-right: 15px; // Adjust padding for RTL

  //   li {
  //     font-size: $font-size-base;
  //     line-height: 1.6;
  //     margin-bottom: 10px;

  //     @include breakpoint-md {
  //       font-size: $font-size-sm;
  //     }

  //     @include breakpoint-sm {
  //       font-size: $font-size-xs;
  //     }
  //   }
  // }

  // Add spacing between sections
  & > * {
    margin-bottom: 30px;

    @include breakpoint-sm {
      margin-bottom: 20px;
    }
  }
}

// Footer container for styling the footer area
.footer-container {
  background-color: $background-color;
  padding: 20px;
  text-align: center;

  p {
    font-size: $font-size-base;
    color: $secondary-color;
  }

  a {
    color: $primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
