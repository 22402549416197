.home-clients-projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;
  padding: 50px 30px;

  .clients-header-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: flex-start;

    .title-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      h1 {
        font-size: $title-font-size-xs;
      }

      @include breakpoint-lg {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }

    p {
      width: 100%;

      // @include breakpoint-lg {
      //   width: 80%;
      // }
    }
  }

  .clients-projects-list-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;

    @include breakpoint-lg {
      flex-direction: row;
      justify-content: space-evenly;
    }

    .client-project-card-container {
      background-size: cover;
      background-position: center;
      height: 350px;
      width: 350px;
      display: flex;
      align-items: flex-end;

      @include breakpoint-lg {
        height: 300px;
        width: 300px;
      }

      @include breakpoint-xl {
        height: 350px;
        width: 350px;
      }

      .card-content-container {
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        color: $white-color;

        button {
          width: 51.672px;
          height: 45.134px;
          flex-shrink: 0;
          border: 1px solid #fff;
          border-radius: 8px;
          color: #fff;
          font-size: 18px;
          background-color: #323333ba;
          // cursor: pointer;
        }
      }
    }
  }
}

// .home-clients-projects-container {
//   padding: 100px 0;
//   display: flex;
//   flex-direction: column;
//   gap: 50px;
//   .header-container {
//     display: flex;
//     flex-direction: column;
//     align-items: start;
//     gap: 20px;

//     .title-container {
//       width: 100%;
//       display: flex;
//       justify-content: space-between;
//     }
//   }

//   .clients-projects-list-container {
//     display: flex;
//     gap: 20px;
//     padding: 0 90px;
//     justify-content: space-evenly;

//     .client-project-card-container {
//       background-size: cover;
//       background-position: center;
//       height: 350px;
//       width: 350px;
//       border-radius: 14px;
//       display: flex;
//       align-items: flex-end;

//       .card-content-container {
//         width: 100%;
//         display: flex;
//         justify-content: space-between;
//         padding: 20px;
//         background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 100%);
//         color: #fff;

//         button {
//           width: 51.672px;
//           height: 45.134px;
//           flex-shrink: 0;
//           border: 1px solid #fff;
//           border-radius: 8px;
//           color: #fff;
//           font-size: 18px;
//           background-color: #323333ba;
//           cursor: pointer;
//         }
//       }
//     }
//   }
// }
