// _base.scss
@import url("https://fonts.googleapis.com/css2?family=Varela+Round&display=swap");

// CSS Reset to remove default margin and padding
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  // Consider setting a default font-size for responsive typography
  font-size: 16px; // This is typically the default but is good to define
}

body {
  font-family: "Arial", sans-serif; // Define your default font-family
  line-height: 1.6; // A common line-height for readability
  direction: rtl;
  font-family: "Varela Round", sans-serif;
  // background-color: $background-color;
}
button {
  cursor: pointer;
}
