// Color palette
$primary-color: #0652e9;
$secondary-color: #6c757d;
$success-color: #28a745;
$error-color: #dc3545;
$background-color: #e6e6e6;
$white-color: #fff;

// Font Sizes
$font-size-xs: 0.75rem; // 12px
$font-size-sm: 0.875rem; // 14px
$font-size-base: 1rem; // 16px
$font-size-lg: 1.25rem; // 20px

// Title Sizes
$title-font-size-xxxs: 0.75rem; // 16px
$title-font-size-xxs: 1rem; // 16px
$title-font-size-xs: 1.25rem; // 20px
$title-font-size-sm: 1.5rem; // 24px
$title-font-size-md: 2rem; // 32px
$title-font-size-lg: 2.5rem; // 40px
$title-font-size-xl: 2.75rem; // 40px
$title-font-size-xxl: 3rem; // 48px

// Subtitle Sizes
$subtitle-font-size-sm: 1.25rem; // 20px
$subtitle-font-size-md: 1.5rem; // 24px
$subtitle-font-size-lg: 1.75rem; // 28px

// Breakpoints
$breakpoint-xs: 320px;
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1400px;
