.headerContainer {
  position: sticky;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  background-color: #fff;
  border-bottom: 1px solid #eaeaea;
  top: 0;
  z-index: 1000;

  .hamburgerMenu {
    display: block;
    font-size: 24px;
    cursor: pointer;

    @include breakpoint-md {
      display: none;
    }
  }
}



// .header-container {
//   position: relative;
//   display: flex;
//   flex-direction: row-reverse;
//   align-items: center;
//   justify-content: space-between;
//   padding: 15px 30px;

//   @include breakpoint-md {
//     flex-direction: row;
//     padding: 10px 90px;
//   }

//   .logo-container {
//     // width: 200px;
//     height: 50px;

//     img {
//       width: 100%;
//       height: 100%;
//     }

//     @include breakpoint-md {
//       width: 200px;
//       height: 65px;
//     }
//   }

//   .menu-open {
//     display: flex;
//     flex-direction: column;
//   }
//   .menu-close {
//     display: none;
//   }

//   .nav-menu-container {
//     position: absolute;
//     z-index: 5;
//     background-color: $white-color;
//     height: 100vh;
//     width: 70%;
//     padding: 20px;
//     right: 0;
//     top: 0;

//     @include breakpoint-md {
//       position: unset;
//       height: unset;
//       width: unset;
//     }

//     .close-menu-button {
//       background: inherit;
//       border: none;
//       align-self: flex-start;
//       font-size: $title-font-size-xs;

//       @include breakpoint-md {
//         display: none;
//       }
//     }

//     .nav-links-container {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       gap: 20px;
//       margin-top: 100px;

//       @include breakpoint-md {
//         flex-direction: row;
//         margin-top: 0;
//       }
//     }
//   }

//   .mobile-menu-button {
//     border: none;
//     background: inherit;
//     font-size: $title-font-size-sm;
//   }
// }
