.home-services-container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 50px;
  gap: 50px;

  @include breakpoint-lg {
    flex-direction: row;
    align-items: center;
  }

  .element1,
  .element4 {
    display: none;
    right: 0;

    @include breakpoint-lg {
      display: block;
      position: absolute;
      z-index: -1;
    }
  }

  .element4 {
    bottom: -80px;
    z-index: -2;
  }

  .content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;

    @include breakpoint-lg {
      width: 100%;
    }

    .element2 {
      position: absolute;
      width: 178px;
      height: 141px;
      z-index: -1;
      top: -100px;
    }

    .element3 {
      position: absolute;
      width: 178px;
      height: 141px;
      z-index: -1;
      bottom: -10px;
    }

    button {
      margin-top: 20px;
    }
  }

  .services-list-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include breakpoint-lg {
      flex-direction: row;
    }

    .low-services-container,
    .high-services-container {
      display: flex;
      flex-direction: column;
      gap: 20px;

      // height: 379px;
    }

    .low-services-container{
      @include breakpoint-lg{
        margin-top: 30px;
      }
    }
    
    .high-services-container{
      @include breakpoint-lg{
        margin-top: -30px;
      }
    }

    .service-card-container {
      border-radius: 30px;
      background: #fff;
      box-shadow: 0px 0px 3px 1px rgb(6 82 233 / 11%);
      border: 1px solid var(--Foundation-Blue-B50, #e6eefd);

      display: flex;
      flex-direction: column;
      gap: 20px;
      padding: 20px;

      h1 {
        font-size: $title-font-size-xs;
      }

      .service-img-container {
        width: 75px;
        height: 75px;
        padding: 10px;
        border-radius: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// .home-services-container {
//   height: 797px;
//   position: relative;
//   display: flex;

//   .element1 {
//     position: absolute;
//     top: 50px;
//     right: 0;
//     z-index: 2;
//   }
//   .element4 {
//     position: absolute;
//     bottom: 0;
//     right: 0;
//     z-index: 1;
//   }
//   .element3 {
//     position: absolute;
//     bottom: 75px;
//     left: 0;
//     z-index: 1;
//   }

//   .content-container {
//     z-index: 3;
//     padding: 0 90px;
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     width: 600px;
//     gap: 25px;

//     h2 {
//       z-index: 1;
//     }

//     .element2 {
//       //   z-index: 4;
//       position: absolute;
//       top: 110px;
//     }
//   }

//   .services-list-container {
//     position: absolute;
//     right: 200px;
//     display: flex;
//     z-index: 5;
//     gap: 100px;

//     .low-services-container,
//     .high-services-container {
//       display: flex;
//       flex-direction: column;
//       gap: 30px;
//     }

//     .low-services-container {
//       margin-top: 100px;
//       top: 100px;
//     }

//     .service-card-container {
//       width: 346px;
//       height: 379px;
//       flex-shrink: 0;
//       border-radius: 30px;
//       background: #fff;
//       box-shadow: 0px 10px 50px 0px rgba (198, 207, 221, 0.47);
//       border: 1px solid var(--Foundation-Blue-B50, #e6eefd);

//       display: flex;
//       flex-direction: column;
//       gap: 30px;
//       padding: 30px;

//       .service-img-container {
//         width: 75px;
//         height: 75px;
//         padding: 10px;
//         border-radius: 20px;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }

//       h1 {
//         width: 264px;
//         font-size: 24px;
//         font-style: normal;
//         font-weight: 500;
//         line-height: 30px; /* 125% */
//       }

//       p {
//         width: 278px;
//         color: #757575;
//         font-size: 14px;
//         font-style: normal;
//         font-weight: 400;
//         line-height: 24px; /* 171.429% */
//       }
//     }
//   }
// }
