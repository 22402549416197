.main-screen{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #00000091;
    z-index: 4;
    cursor: pointer;
}

.main-button{
    background: #2639ed;
    border-radius: 60px;
    border: none;
    color: #fff;
    cursor: pointer;
    padding: 20px 40px;
    font-weight: bolder;
}